import React from "react";
import styled from "styled-components";

const BaseUrl = ({ children }) => {
  return (
    <Wrapper>
      <span>BASE URL</span>
      <p>{children}</p>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  & > p {
    display: flex;
    align-items: center;
    word-break: break-word;
  }
  & > span {
    background-color: #52b4743d;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    margin-right: 20px;
    min-width: 95px;
    @media (max-width: 900px) {
      margin-bottom: 10px;
    }
  }
`;

export default BaseUrl;
