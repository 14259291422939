import styled from "styled-components";

export const Summary = styled.div`
  max-height: 830px;
  min-height: 500px;
  margin-left: 35px;
  padding-right: 25px;
  padding-top: 15px;
  display: flex;
  flex-direction: column;
  @media (max-width: 900px) {
    border: none;
    padding: 10px;
    height: fit-content;
    max-height: 500px;
    overflow-y: auto;
    margin: 0;
  }
  border-right: ${({ theme }) => theme.border};
  & > button {
    min-height: 32px;
    font-size: 14px;
    text-align: end;
    border: none;
    outline: none;
    cursor: pointer;
    background: transparent;
    max-width: 250px;
    color: ${({ theme }) => theme.text};
    display: flex;
    justify-content: right;
    align-items: center;
    margin: 5px 0;
    & > span {
      background-color: #52b4743d;
      border-radius: 5px;
      margin-left: 10px;
      height: 25px;
      max-width: 25px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;
