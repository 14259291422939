import styled from "styled-components";

export const Paragraph = styled.p`
  font-size: 14px;
  line-height: 25px;
  margin: 10px 0;
  padding-left: ${({ margin }) => (margin ? "80px" : "0")};
  & > p {
    display: flex;
  }
  & > span {
    font-weight: 900;
  }
  ${({ mail }) =>
    mail &&
    `
@media (max-width: 450px) {
  padding: 0;
  margin: 0;
}
`}
`;
