import React from "react";
import styled from "styled-components";

const StaticExample = ({ children, title }) => {
  return (
    <Example>
      <Head>{title ? title : "Request example"}</Head>
      <Container>{children}</Container>
    </Example>
  );
};

const Example = styled.div`
  background-color: rgb(36, 63, 91);
  border-radius: 5px;
  color: rgb(249, 248, 245);
  max-width: 850px;
  margin: 20px 0;
  overflow: hidden;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  min-height: 50px;
  font-size: 13px;
  padding: 10px;
  line-height: 20px;
`;

const Head = styled.div`
  width: 100%;
  height: 30px;
  font-size: 16px;
  background-color: rgb(20, 45, 71);
  overflow: hidden;
  display: flex;
  align-items: center;
  padding: 0 10px;
`;

export default StaticExample;
