import styled from "styled-components";

export const SubTitle = styled.p`
  width: 100%;
  font-size: 24px;
  font-weight: 500;
  color: #52b474;
  margin-top: 10px;
  color: ${({ theme }) => theme.text};
  margin: 40px 0px 20px 0;
  display: flex;
  align-items: center;
  & > div {
    display: flex;
    flex-direction: column;
  }
  & > div:first-child {
    height: 80px;
    width: 80px;
  }
  & > div > span {
    font-style: italic;
    font-size: 20px;
    font-weight: normal;
    color: #52b474;
    margin-top: 10px;
  }
`;
